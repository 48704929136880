import React, { useCallback } from "react";
import { ethers } from "ethers";
import { useQuery } from "../../hooks/useQuery";
import { ContractContainer } from "../../hooks/useContract";
import { WalletConnectPageStateContainer } from "../../hooks/useWalletConnectPageState";
import { useTranslation } from "react-i18next";
import "./../../i18n/config";
import { Web3ProviderContainer } from "../../hooks/useWeb3Provider";

export const CancelerActionArea = ({
  itemBelongsCurrentUser,
}: {
  itemBelongsCurrentUser: boolean;
}) => {
  const { t } = useTranslation(["wcp"]);
  const { tradeID, poster } = useQuery();
  const { currentUserAddress } = Web3ProviderContainer.useContainer();
  const {
    setCancelingState,
    cancelingState,
  } = WalletConnectPageStateContainer.useContainer();
  const { marketContract } = ContractContainer.useContainer();

  const onCancel = useCallback(async ({ tradeID }: { tradeID: number }) => {
    setCancelingState("executing");
    try {
      const tx: ethers.providers.TransactionResponse = await marketContract!.cancelTrade(
        tradeID
      );
      const result = await tx.wait();
      if (result.status == 1) {
        setCancelingState("success");
      } else {
        // 失敗するトランザクションは metamask 実行前にはじかれるはずなのでここは通らないかもしれない
        console.log("failure [result]:", result);
        setCancelingState("failure");
      }
    } catch (err) {
      console.log(err);
      setCancelingState("failure");
    }
  }, []);

  if (poster != currentUserAddress && !itemBelongsCurrentUser)
    return (
      <p className="message">{t("wcp:canceler:action:message:wrongAccount")}</p>
    );

  if (itemBelongsCurrentUser || cancelingState == "success") return null;

  if (cancelingState == "executing")
    return (
      <p className="message">{t("wcp:canceler:action:message:executing")}</p>
    );

  if (cancelingState == "failure")
    return (
      <p className="message">{t("wcp:canceler:action:message:failure")}</p>
    );

  return (
    <button onClick={() => onCancel({ ...{ tradeID } })}>
      {t("wcp:canceler:action:button:cancel")}
    </button>
  );
};
