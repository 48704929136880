import React, { useState } from "react";
import { MaticUnit } from "../../types/MaticUnit";
import { ApproveButton } from "./ApproveButton";
import { PriceSelect } from "./PriceSelect";
import { SellButton } from "./SellButton";
import { WalletConnectPageStateContainer } from "../../hooks/useWalletConnectPageState";
import { useTranslation } from "react-i18next";
import "./../../i18n/config";

export const SellerActionArea = ({
  itemBelongsCurrentUser,
}: {
  itemBelongsCurrentUser: boolean;
}) => {
  const { t } = useTranslation(["wcp"]);
  const [price, setPrice] = useState<number>(0);
  const [unit, setUnit] = useState<MaticUnit>("weiMatic");
  const { sellingState } = WalletConnectPageStateContainer.useContainer();

  if (!itemBelongsCurrentUser || sellingState == "success") return null;

  if (sellingState == "init")
    return (
      <>
        <p className="description">
          {t("wcp:seller:action:message:description")}
        </p>
        <ApproveButton />
      </>
    );

  if (sellingState == "givingPermission" || sellingState == "executing")
    return (
      <p className="message">{t("wcp:seller:action:message:executing")}</p>
    );

  if (sellingState == "permitted")
    return (
      <>
        <PriceSelect {...{ price, unit, setPrice, setUnit }} />
        <SellButton price={price} unit={unit} />
      </>
    );

  if (sellingState == "failure")
    return <p className="message">{t("wcp:seller:action:message:failure")}</p>;

  return null;
};
