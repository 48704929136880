import WalletConnectProvider from "@walletconnect/ethereum-provider";
import { ethers, providers } from "ethers";
import { useState, useCallback } from "react";
import { createContainer } from "unstated-next";
import { INFURA_ID, WEB3_RPC_URL } from "../constants";

const useProvider = () => {
  const [currentUserAddress, setCurrentUserAddress] = useState<String>();
  const [wcProvider, setWCProvider] = useState<WalletConnectProvider>();

  const setupWCProvider = useCallback(async () => {
    const provider = new WalletConnectProvider({
      rpc: {
        80001: WEB3_RPC_URL,
      },
      infuraId: INFURA_ID,
      // qrcodeModalOptions: {
      //   mobileLinks: [
      //     "rainbow",
      //     "metamask",
      //     "argent",
      //     "trust",
      //     "imtoken",
      //     "pillar",
      //   ],
      // },
    });
    //  Enable session (triggers QR Code modal)
    await provider.enable();

    setWCProvider(provider);
    return provider;
  }, []);

  const checkNetworkIsCorrect = useCallback(
    async (provider: providers.Web3Provider) => {
      const currentNetwork = await provider.getNetwork();
      return currentNetwork.chainId == 80001;
    },
    []
  );

  const getCurrentUser = useCallback(
    async (provider: WalletConnectProvider) => {
      const accounts: [string] = await provider.request({
        method: "eth_requestAccounts",
      });
      setCurrentUserAddress(accounts[0]);
    },
    [setCurrentUserAddress]
  );

  return {
    currentUserAddress,
    wcProvider,
    setWCProvider,
    setupWCProvider,
    checkNetworkIsCorrect,
    getCurrentUser,
  };
};

export const Web3ProviderContainer = createContainer(useProvider);
