import { useState } from "react";
import { BuyingState } from "../types/BuyingState";
import { PreparingState } from "../types/PreparingState";
import { SellingState } from "../types/SellingState";
import { UserType } from "../types/UserType";
import { createContainer } from "unstated-next";
import { CancelingState } from "../types/CancelingState";

const useWalletConnectPageState = () => {
  const [preparingState, setPreparingState] = useState<PreparingState>(
    "setupProvider"
  );
  const [userType, setUserType] = useState<UserType>();
  const [sellingState, setSellingState] = useState<SellingState>();
  const [buyingState, setBuyingState] = useState<BuyingState>();
  const [cancelingState, setCancelingState] = useState<CancelingState>();

  return {
    preparingState,
    setPreparingState,
    userType,
    setUserType,
    sellingState,
    setSellingState,
    buyingState,
    setBuyingState,
    cancelingState,
    setCancelingState,
  };
};

export const WalletConnectPageStateContainer = createContainer(
  useWalletConnectPageState
);
