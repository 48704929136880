import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// [期待するqueryStringのパラメーター]
// * Userが Seller の時
//   - itemID
// * Userが Buyer の時
//   - tradeID
//   - poster
//   - itemID
//   - price
export const useQuery = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  return {
    tradeID: parseInt(query.get("tradeID")!),
    poster: query.get("poster")!,
    itemID: parseInt(query.get("itemID")!),
    price: parseInt(query.get("price")!),
  };
};
