import React, { useEffect } from "react";
import { ItemOwnerAddressContainer } from "../hooks/useItemOwnerAddress";
import { MetadataContainer } from "../hooks/useMetadata";
import { useQuery } from "../hooks/useQuery";
import { BuyingState } from "../types/BuyingState";
import { SellingState } from "../types/SellingState";
import { ipfsURIToGatewayURI } from "../utils/ipfsURIToGatewayURI";
import { Web3ProviderContainer } from "../hooks/useWeb3Provider";
import { useTranslation } from "react-i18next";
import "./../i18n/config";
import { CancelingState } from "../types/CancelingState";

export const ItemInfoTable = ({
  buyingState,
  sellingState,
  cancelingState,
  itemBelongsCurrentUser,
}: {
  buyingState?: BuyingState;
  sellingState?: SellingState;
  cancelingState?: CancelingState;
  itemBelongsCurrentUser?: boolean;
}) => {
  const { t } = useTranslation(["wcp"]);
  const { price } = useQuery();
  const { currentUserAddress } = Web3ProviderContainer.useContainer();
  const { metadata } = MetadataContainer.useContainer();
  const {
    itemOwnerAddress,
    resolveCurrentOwner,
  } = ItemOwnerAddressContainer.useContainer();

  useEffect(() => {
    (async () => {
      await resolveCurrentOwner();
    })();
  }, [buyingState, sellingState, cancelingState, itemOwnerAddress]);

  if (!metadata) return null;
  return (
    <table>
      <tbody>
        <tr>
          <th>{t("wcp:itemInfo:yourAddress")}</th>
          <td>{currentUserAddress}</td>
        </tr>
        <tr>
          <th>{t("wcp:itemInfo:itemName")}</th>
          <td>{metadata.name}</td>
        </tr>
        <tr>
          <th>{t("wcp:itemInfo:itemDescription")}</th>
          <td>{metadata.description}</td>
        </tr>
        <tr>
          <th>{t("wcp:itemInfo:itemImage")}</th>
          <td>
            <img
              style={{ maxWidth: "50vw" }}
              src={ipfsURIToGatewayURI({ ipfsURI: metadata.image })}
            />
          </td>
        </tr>
        <tr>
          <th>{t("wcp:itemInfo:currentOwner")}</th>
          <td>{itemOwnerAddress}</td>
        </tr>
        {price && !itemBelongsCurrentUser ? (
          <tr>
            <th>{t("wcp:itemInfo:price")}</th>
            <td>{price} Wei MATIC</td>
          </tr>
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
};
