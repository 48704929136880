const NFT_STORAGE_IPFS_GATEWAY_URI = "https://nftstorage.link";

export const ipfsURIToGatewayURI = ({ ipfsURI }: { ipfsURI: string }) => {
  if (ipfsURI.startsWith("https")) {
    return ipfsURI;
  }
  const uri =
    NFT_STORAGE_IPFS_GATEWAY_URI + "/ipfs/" + ipfsURI.replace("ipfs://", "");
  return uri;
};
