import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import logo from "./logo.svg";
import React from "react";
import { MetadataContainer } from "./hooks/useMetadata";
import { Web3ProviderContainer } from "./hooks/useWeb3Provider";
import { ContractContainer } from "./hooks/useContract";
import { WalletConnectPageStateContainer } from "./hooks/useWalletConnectPageState";
import { WalletConnectPage } from "./WalletConnectPage";
import { ItemOwnerAddressContainer } from "./hooks/useItemOwnerAddress";

function App() {
  return (
    <Router>
      <WalletConnectPageStateContainer.Provider>
        <Web3ProviderContainer.Provider>
          <ContractContainer.Provider>
            <MetadataContainer.Provider>
              <ItemOwnerAddressContainer.Provider>
                <div className="App">
                  <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <Routes>
                      <Route path="/" element={<WalletConnectPage />} />
                    </Routes>
                  </header>
                </div>
              </ItemOwnerAddressContainer.Provider>
            </MetadataContainer.Provider>
          </ContractContainer.Provider>
        </Web3ProviderContainer.Provider>
      </WalletConnectPageStateContainer.Provider>
    </Router>
  );
}

export default App;
