import React, { useEffect, useState } from "react";
import { ItemInfoTable } from "../ItemInfoTable";
import { WalletConnectPageStateContainer } from "../../hooks/useWalletConnectPageState";
import { ItemOwnerAddressContainer } from "../../hooks/useItemOwnerAddress";
import { Web3ProviderContainer } from "../../hooks/useWeb3Provider";
import { BuyerActionArea } from "./BuyerActionArea";
import { useTranslation } from "react-i18next";
import "./../../i18n/config";

export const BuyerView = () => {
  const { t } = useTranslation(["wcp"]);
  const {
    buyingState,
    setBuyingState,
  } = WalletConnectPageStateContainer.useContainer();
  const { itemOwnerAddress } = ItemOwnerAddressContainer.useContainer();
  const { currentUserAddress } = Web3ProviderContainer.useContainer();
  const [itemBelongsCurrentUser, setItemBelongsCurrentUser] = useState(false);

  useEffect(() => {
    setBuyingState("init");
  }, []);

  // itemOwner が currentUser かチェックする
  useEffect(() => {
    if (!currentUserAddress || !itemOwnerAddress) return;

    if (currentUserAddress.toUpperCase() == itemOwnerAddress.toUpperCase()) {
      setItemBelongsCurrentUser(true);
    }
  }, [itemOwnerAddress, currentUserAddress]);

  return (
    <>
      {itemBelongsCurrentUser ? (
        <p>{t("wcp:buyer:successTitle")}</p>
      ) : (
        <p>{t("wcp:buyer:title")}</p>
      )}

      <ItemInfoTable
        buyingState={buyingState}
        itemBelongsCurrentUser={itemBelongsCurrentUser}
      />
      <BuyerActionArea {...{ itemBelongsCurrentUser }} />
    </>
  );
};
