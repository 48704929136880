import React, { useCallback } from "react";
import { ethers } from "ethers";
import { useQuery } from "../../hooks/useQuery";
import { ContractContainer } from "../../hooks/useContract";
import { WalletConnectPageStateContainer } from "../../hooks/useWalletConnectPageState";
import { useTranslation } from "react-i18next";
import "./../../i18n/config";

export const BuyerActionArea = ({
  itemBelongsCurrentUser,
}: {
  itemBelongsCurrentUser: boolean;
}) => {
  const { t } = useTranslation(["wcp"]);
  const { tradeID, price } = useQuery();
  const {
    setBuyingState,
    buyingState,
  } = WalletConnectPageStateContainer.useContainer();
  const { marketContract } = ContractContainer.useContainer();

  const onBuy = useCallback(
    async ({ tradeID, price }: { tradeID: number; price: number }) => {
      setBuyingState("executing");
      try {
        const wei = ethers.utils.parseUnits(price.toString(), "wei");
        console.log("price: ", wei);
        const tx: ethers.providers.TransactionResponse = await marketContract!.executeTrade(
          tradeID,
          { value: wei }
        );
        const result = await tx.wait();

        if (result.status == 1) {
          setBuyingState("success");
        } else {
          // 失敗するトランザクションは metamask 実行前にはじかれるはずなのでここは通らないかもしれない
          console.log("failure [result]:", result);
          setBuyingState("failure");
        }
      } catch (err) {
        console.log(err);
        setBuyingState("failure");
      }
    },
    []
  );

  if (itemBelongsCurrentUser || buyingState == "success") return null;

  if (buyingState == "executing")
    return <p className="message">{t("wcp:buyer:action:message:executing")}</p>;

  if (buyingState == "failure")
    return <p className="message">{t("wcp:buyer:action:message:failure")}</p>;

  return (
    <button onClick={() => onBuy({ ...{ tradeID, price } })}>
      {t("wcp:buyer:action:button:buy")}
    </button>
  );
};
