import React, { useCallback, useEffect } from "react";
import { useQuery } from "./hooks/useQuery";
import { WalletConnectPageStateContainer } from "./hooks/useWalletConnectPageState";
import { ContractContainer } from "./hooks/useContract";
import { MetadataContainer } from "./hooks/useMetadata";
import { Web3ProviderContainer } from "./hooks/useWeb3Provider";
import { providers } from "ethers";
import { BuyerView } from "./components/buyerview/BuyerView";
import { SellerView } from "./components/sellerview/SellerView";
import "./WalletConnectPage.css";
import { useTranslation } from "react-i18next";
import "./i18n/config";
import { CancelerView } from "./components/cancelerview/CancelerView";
import { CUBIFOX_TOKEN_CONTRACT_ADDRESS } from "./constants";

export const WalletConnectPage = () => {
  const { t } = useTranslation(["wcp"]);
  const { tradeID, price } = useQuery();
  const {
    userType,
    setUserType,
    preparingState,
    buyingState,
    sellingState,
    cancelingState,
    setPreparingState,
  } = WalletConnectPageStateContainer.useContainer();
  const {
    wcProvider,
    setWCProvider,
    setupWCProvider,
    getCurrentUser,
  } = Web3ProviderContainer.useContainer();
  const {
    linkMarketContract,
    linkTokenContract,
    tokenContract,
  } = ContractContainer.useContainer();
  const { fetchMetadata, metadata } = MetadataContainer.useContainer();

  // 今の状態を確認するためだけの useEffect
  useEffect(() => {
    console.log({
      wcProvider,
      preparingState,
      buyingState,
      sellingState,
      cancelingState,
      metadata,
    });
  }, [wcProvider, preparingState, buyingState, sellingState, cancelingState]);

  // walletconnectでウォレットとのセッションをつなげる
  useEffect(() => {
    if (wcProvider) return;

    setPreparingState("setupProvider");
    (async () => {
      await setupWCProvider();
    })();
  }, [wcProvider]);

  // currentUserを取得してcontractを使う準備をする
  useEffect(() => {
    if (!wcProvider) return;

    // currentUserを取得
    void getCurrentUser(wcProvider);

    // contractを使う準備
    const provider = new providers.Web3Provider(wcProvider);

    // なぜかmetamaskから戻ってすぐはcontractを見つけられないので一度リロードする
    (async () => {
      if ((await provider.getCode(CUBIFOX_TOKEN_CONTRACT_ADDRESS)) == "0x")
        window.location.reload();
    })();

    linkMarketContract(provider);
    linkTokenContract(provider);
  }, [wcProvider]);

  // metadata を取得
  useEffect(() => {
    if (!tokenContract || metadata) return;

    setPreparingState("gettingMetadata");
    fetchMetadata(tokenContract).then((err) => {
      if (!err) {
        setPreparingState("ready");
        return;
      }
    });
  }, [tokenContract]);

  // setUserType する
  useEffect(() => {
    if (tradeID) {
      if (price) {
        setUserType("buyer");
      } else {
        setUserType("canceler");
      }
    } else {
      setUserType("seller");
    }
  }, [tradeID]);

  // Walletとのセッションを切断する
  const onDisconnect = useCallback(async () => {
    if (!wcProvider) return;

    await wcProvider.disconnect();
    window.localStorage.removeItem("walletconnect");
    setWCProvider(undefined);
  }, [wcProvider]);

  if (!wcProvider) return <h2>{t("wcp:preparing:needWindowReload")}</h2>;
  if (preparingState != "ready") return <></>;

  return (
    <>
      <button onClick={onDisconnect}>{t("wcp:preparing:disconnect")}</button>
      {userType == "buyer" && <BuyerView />}
      {userType == "seller" && <SellerView />}
      {userType == "canceler" && <CancelerView />}
    </>
  );
};
