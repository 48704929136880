import i18n from "i18next";
import wcp from "./ja/wallet_connect_page.json";
import { initReactI18next } from "react-i18next";

export const resources = {
  ja: {
    wcp,
  },
  en: {
    wcp,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "ja",
  ns: ["wcp"],
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
  debug: process.env.NODE_ENV === "development",
});
