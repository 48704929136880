import React, { useCallback } from "react";
import { ethers } from "ethers";
import { weiPrice } from "../../utils/weiPrice";
import { MaticUnit } from "../../types/MaticUnit";
import { useQuery } from "../../hooks/useQuery";
import { ContractContainer } from "../../hooks/useContract";
import { WalletConnectPageStateContainer } from "../../hooks/useWalletConnectPageState";
import { useTranslation } from "react-i18next";
import "./../../i18n/config";

export const SellButton = ({
  price,
  unit,
}: {
  price: number;
  unit: MaticUnit;
}) => {
  const { t } = useTranslation(["wcp"]);
  const { itemID } = useQuery();
  const { marketContract } = ContractContainer.useContainer();
  const { setSellingState } = WalletConnectPageStateContainer.useContainer();

  const onSell = useCallback(async () => {
    try {
      setSellingState("executing");
      const wei = ethers.utils.parseUnits(
        weiPrice({ price: price, unit: unit }).toString(),
        "wei"
      );
      const tx: ethers.providers.TransactionResponse = await marketContract!.openTrade(
        itemID,
        wei
      );
      const result = await tx.wait();
      if (result.status == 1) {
        setSellingState("success");
      } else {
        // 失敗するトランザクションは metamask 実行前にはじかれるはずなのでここは通らないかもしれない
        setSellingState("failure");
      }
    } catch (err) {
      console.log(err);
      setSellingState("failure");
    }
  }, [marketContract, price]);

  if (price >= 0)
    return (
      <button onClick={onSell}>{t("wcp:seller:action:button:sell")}</button>
    );

  return <button disabled>{t("wcp:seller:action:button:sell")}</button>;
};
