import { useState, useCallback } from "react";
import { ethers } from "ethers";
import { createContainer } from "unstated-next";
import {
  CUBIFOX_MARKET_CONTRACT_ADDRESS,
  CUBIFOX_MARKET_CONTRACT_ABI,
  CUBIFOX_TOKEN_CONTRACT_ADDRESS,
  CUBIFOX_TOKEN_CONTRACT_ABI,
} from "../constants";

const useContract = () => {
  const [marketContract, setMarketContract] = useState<ethers.Contract>();
  const [tokenContract, setTokenContract] = useState<ethers.Contract>();

  const linkMarketContract = useCallback(
    (provider: ethers.providers.Web3Provider) => {
      try {
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          CUBIFOX_MARKET_CONTRACT_ADDRESS,
          CUBIFOX_MARKET_CONTRACT_ABI,
          provider
        );
        setMarketContract(contract.connect(signer));
      } catch (err) {
        throw err;
      }
    },
    [setMarketContract]
  );

  const linkTokenContract = useCallback(
    (provider: ethers.providers.Web3Provider) => {
      try {
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          CUBIFOX_TOKEN_CONTRACT_ADDRESS,
          CUBIFOX_TOKEN_CONTRACT_ABI,
          provider
        );
        const contractWithSigner = contract.connect(signer);
        setTokenContract(contractWithSigner);
        return contractWithSigner;
      } catch (err) {
        throw err;
      }
    },
    [setTokenContract]
  );

  return {
    marketContract,
    tokenContract,
    linkMarketContract,
    linkTokenContract,
  };
};

export const ContractContainer = createContainer(useContract);
