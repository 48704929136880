export const CUBIFOX_MARKET_CONTRACT_ADDRESS =
  "0x9967152563b08006413FB04eCa485D27c445afD2";

export const CUBIFOX_MARKET_CONTRACT_ABI = [
  "function tradeIdCounter() view returns (uint256)",
  "function openTrade(uint256 _itemId, uint256 _price)",
  "function executeTrade(uint256 _tradeId) payable",
  "function cancelTrade(uint256 _tradeId)",
];

export const CUBIFOX_TOKEN_CONTRACT_ADDRESS =
  "0xF97Bd91B2399d4b45232717f1288C0f1dC9eEe09";

export const CUBIFOX_TOKEN_CONTRACT_ABI = [
  "function approve(address to, uint256 tokenId)",
  "function getApproved(uint256 tokenId) view returns (address operator)",
  "function tokenURI(uint256 tokenId) public view returns (string memory)",
  "function ownerOf(uint256 tokenId) public view returns (address)",
];

export const WEB3_RPC_URL =
  "https://polygon-mumbai.infura.io/v3/1622077723654f928aaae17148265be5";

export const INFURA_ID = "1622077723654f928aaae17148265be5";
