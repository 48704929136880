import React, { useCallback, useEffect } from "react";
import { ItemInfoTable } from "../ItemInfoTable";
import { SellerActionArea } from "./SellerActionArea";
import { useQuery } from "../../hooks/useQuery";
import { ContractContainer } from "../../hooks/useContract";
import { WalletConnectPageStateContainer } from "../../hooks/useWalletConnectPageState";
import { CUBIFOX_MARKET_CONTRACT_ADDRESS } from "../../constants";
import { ItemOwnerAddressContainer } from "../../hooks/useItemOwnerAddress";
import { Web3ProviderContainer } from "../../hooks/useWeb3Provider";
import { useTranslation } from "react-i18next";
import "./../../i18n/config";

export const SellerView = () => {
  const { t } = useTranslation(["wcp"]);
  const { itemID } = useQuery();
  const { tokenContract } = ContractContainer.useContainer();
  const {
    sellingState,
    setSellingState,
  } = WalletConnectPageStateContainer.useContainer();
  const {
    itemOwnerAddress,
    resolveCurrentOwner,
  } = ItemOwnerAddressContainer.useContainer();
  const { currentUserAddress } = Web3ProviderContainer.useContainer();

  // sellingStateを初期化
  useEffect(() => {
    setSellingState("init");
  }, []);

  // sellingStateが init か success の時にitemOwnerを再取得する
  useEffect(() => {
    if (sellingState == "success" || sellingState == "init") {
      (async () => {
        resolveCurrentOwner();
      })();
    }
  }, [sellingState]);

  // sellingState が givingPermission の時 tokenContract.getApproved() を3秒ごとに実行する
  useEffect(() => {
    if (!tokenContract) return;
    if (sellingState != "givingPermission") return;

    const timer = setInterval(async () => {
      try {
        const approvedOperator = await tokenContract.getApproved(itemID);
        if (approvedOperator == CUBIFOX_MARKET_CONTRACT_ADDRESS) {
          setSellingState("permitted");
        }
      } catch (err) {
        console.log(err);
      }
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [tokenContract, sellingState]);

  const itemBelongsCurrentUser = useCallback(() => {
    if (!currentUserAddress || !itemOwnerAddress) return false;
    return currentUserAddress.toUpperCase() === itemOwnerAddress.toUpperCase();
  }, [currentUserAddress, itemOwnerAddress]);

  const titleText = (): string => {
    //ユーザーがアイテムの正しい所有者の時
    if (itemBelongsCurrentUser()) {
      return t("wcp:seller:title");
    }
    // アイテムをCubifoxが所有時している場合
    if (
      itemOwnerAddress &&
      itemOwnerAddress!.includes(CUBIFOX_MARKET_CONTRACT_ADDRESS)
    ) {
      return t("wcp:seller:successTitle");
    }
    // ユーザーがアイテムの正しい所有者でない時
    else if (itemOwnerAddress != currentUserAddress) {
      return t("wcp:seller:wrongAccountTitle");
    }
    return "";
  };

  return (
    <>
      <p>{titleText()}</p>

      <ItemInfoTable sellingState={sellingState} />
      <SellerActionArea itemBelongsCurrentUser={itemBelongsCurrentUser()} />
    </>
  );
};
