import { MaticUnit } from "../types/MaticUnit";

export const weiPrice = ({
  price,
  unit,
}: {
  price: number;
  unit: MaticUnit;
}): BigInt => {
  if (unit == "weiMatic") {
    return BigInt(price);
  } else if (unit == "gweiMatic") {
    return BigInt(price * 10 ** 9);
  } else {
    return BigInt(price * 10 ** 18);
  }
};
