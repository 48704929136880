import { useState, useCallback } from "react";
import { Metadata } from "../types/Metadata";
import { ipfsURIToGatewayURI } from "../utils/ipfsURIToGatewayURI";
import { useQuery } from "./useQuery";
import { ethers } from "ethers";
import { createContainer } from "unstated-next";

const useMetadata = () => {
  const { itemID } = useQuery();

  const [error, setError] = useState<string | null>(null);
  const [metadata, setMetadata] = useState<Metadata>();

  const fetchMetadata = useCallback(
    async (tokenContract: ethers.Contract) => {
      let error: Error;
      setError(null);

      if (!itemID) return;

      try {
        const tokenURI = await tokenContract.tokenURI(itemID);
        const response = await fetch(
          ipfsURIToGatewayURI({ ipfsURI: tokenURI })
        );
        if (response.ok) {
          setMetadata(await response.json());
        } else {
          return Error(await response.text());
        }
      } catch (err) {
        console.log("err", err);
        error = err as Error;
        setError(error.message);
        return error;
      }

      return;
    },
    [itemID]
  );
  return { metadata, fetchMetadata, error };
};

export const MetadataContainer = createContainer(useMetadata);
