import React, { useCallback } from "react";
import { useQuery } from "../../hooks/useQuery";
import { ContractContainer } from "../../hooks/useContract";
import { ethers } from "ethers";
import { WalletConnectPageStateContainer } from "../../hooks/useWalletConnectPageState";
import { CUBIFOX_MARKET_CONTRACT_ADDRESS } from "../../constants";
import { useTranslation } from "react-i18next";
import "./../../i18n/config";

// Contract に ERC721トークン を transfer するために approve してもらう必要がある
// https://github.com/OpenZeppelin/openzeppelin-contracts/blob/cb3f2ab900e39c5ab6e0de6663edf06f573b834f/contracts/token/ERC721/IERC721.sol#L61-L79
export const ApproveButton = () => {
  const { t } = useTranslation(["wcp"]);
  const { itemID } = useQuery();
  const { tokenContract } = ContractContainer.useContainer();
  const { setSellingState } = WalletConnectPageStateContainer.useContainer();
  const onClick = useCallback(
    async ({ itemID }: { itemID: number }) => {
      setSellingState("givingPermission");
      try {
        const tx: ethers.providers.TransactionResponse = await tokenContract!.approve(
          CUBIFOX_MARKET_CONTRACT_ADDRESS,
          itemID
        );
        const result = await tx.wait();
        if (result.status == 1) {
          setSellingState("permitted");
        } else {
          // 失敗するトランザクションは metamask 実行前にはじかれるはずなのでここは通らないかもしれない
          setSellingState("failure");
        }
      } catch (err) {
        console.log(err);
      }
    },
    [tokenContract]
  );

  return (
    <button onClick={() => onClick({ itemID })}>
      {t("wcp:seller:action:button:approve")}
    </button>
  );
};
