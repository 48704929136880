import React, { ChangeEvent } from "react";
import { MaticUnit } from "../../types/MaticUnit";
import { useTranslation } from "react-i18next";
import "./../../i18n/config";

export const PriceSelect = ({
  price,
  unit,
  setPrice,
  setUnit,
}: {
  price: number;
  unit: MaticUnit;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  setUnit: React.Dispatch<React.SetStateAction<MaticUnit>>;
}) => {
  const { t } = useTranslation(["wcp"]);
  return (
    <span>
      <label>{t("wcp:seller:action:label:price")}</label>
      <input
        type="number"
        min="0"
        value={price}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setPrice(parseInt(e.target.value));
        }}
      />
      <select
        value={unit}
        onChange={(e) => {
          setUnit(e.target.value as MaticUnit);
        }}
      >
        <option value="weiMatic">wei MATIC</option>
        <option value="gweiMatic">gwei MATIC</option>
        <option value="matic">MATIC</option>
      </select>
    </span>
  );
};
