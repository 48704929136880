import { useState, useEffect, useCallback } from "react";
import { useQuery } from "./useQuery";
import { ContractContainer } from "./useContract";
import { CUBIFOX_MARKET_CONTRACT_ADDRESS } from "../constants";
import { createContainer } from "unstated-next";
import { WalletConnectPageStateContainer } from "./useWalletConnectPageState";

// useItemOwnerAddress() は状況に応じた itemOwnerAddress を返す
//
// [BuyerViewの場合]
// 1. 基本は tokenContract.ownerOf で取得した値
// 2. 1で取得した値がMarketContractだった場合はposter
//
// [SellerViewの場合]
// 1. 基本は tokenContract.ownerOf で取得した値
// 2. 1で取得した値がMarketContractだった場合は "Cubifox Market"
const useItemOwnerAddress = () => {
  const { poster, itemID } = useQuery();
  const {
    buyingState,
    sellingState,
  } = WalletConnectPageStateContainer.useContainer();
  const { tokenContract } = ContractContainer.useContainer();
  const [itemOwnerAddress, setItemOwnerAddress] = useState<String>();

  const resolveCurrentOwner = useCallback(async () => {
    if (!tokenContract || !itemID) return;
    let address: string = "";

    // BuyerViewの場合の1
    // SellerViewの場合の1
    try {
      address = await tokenContract.ownerOf(itemID);
      setItemOwnerAddress(address);
    } catch (err) {
      console.log("err", err);
    }

    // BuyerViewの場合の2
    if (
      address.toUpperCase() == CUBIFOX_MARKET_CONTRACT_ADDRESS.toUpperCase() &&
      buyingState != undefined
    ) {
      setItemOwnerAddress(poster);
    }

    // SellerViewの場合の2
    if (
      address.toUpperCase() == CUBIFOX_MARKET_CONTRACT_ADDRESS.toUpperCase() &&
      sellingState != undefined
    ) {
      setItemOwnerAddress(
        `Cubifox Market (${CUBIFOX_MARKET_CONTRACT_ADDRESS})`
      );
    }
  }, [itemID, tokenContract, sellingState, buyingState]);

  return { itemOwnerAddress, resolveCurrentOwner };
};

export const ItemOwnerAddressContainer = createContainer(useItemOwnerAddress);
